import React from "react";
import Nav from "./components/Nav";
import Contact from "./components/Contact";

import "../src/projects";

function App() {
  return (
    <div>
      <Nav />

      <Contact />
    </div>
  );
}

export default App;
