import React from "react";

function Contact() {
  return (
    <div id="current-about" className="section is-medium">
      <div class="f-head has-text-centered">
        <h1>Reach Out.</h1>
        <div class="columns is-centered wooski is-flex flex-direction-row links">
          <a class="" href="https://github.com/sagehoshigaki">
            GitHub
          </a>
          <a
            class=""
            href="https://drive.google.com/file/d/11ZnjCsaMovBXWNU51c5qOjaQ6_hId1BK/view?usp=drive_link"
          >
            Resume
          </a>
          <a class="" href="mailto:sagewhite24@gmail.com">
            Email
          </a>
          <a class="" href="https://www.linkedin.com/in/sage-white-224586176/">
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
